import {Injectable} from '@angular/core';
import {ServiceProviderResponse} from '../models/service-provider';
import {map, retry} from 'rxjs/operators';
import {ServiceProviderAuctions, ServiceProviderAuctionInfo} from '../models/service-provider-bids';
import {HttpClient, HttpEventType, HttpParams, HttpResponse} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ServiceProviderBidsService {

    constructor(private http: HttpClient) {
    }

    getCurrentAuctions(page, search, countries, limit) {
        const params = new HttpParams({
            fromObject: {
                page,
                search,
                'countries[]': countries,
                lat: localStorage.getItem('lat'),
                long: localStorage.getItem('long'),
                range_in_meter: '3500000000',
                limit: limit,
                tripDate: new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate()
            }
        });
        return this.http.get<ServiceProviderAuctions[]>('auctions/service-provider', {
            params
        }) .pipe(map(event => event));
    }

    getEnquiry(path) {
        return this.http.get<ServiceProviderAuctionInfo>(`enquiry/${path}`)
            .pipe(map(event => event));
    }

    driverAcceptRequestAPI(enquiryId: string | number) {
        return this.http.put(`enquiry/delivery-request/${enquiryId}`, null);
    }

    createBid(data) {
        return this.http.post('bid', data)
            .pipe(map(event => event));
    }
    enquiry = 0;
    shareLocation(lat, long) {
        return this.http.post('position', {lat, long, enquiry: this.enquiry}).pipe(map(event => event));
    }

    pickUpReached(id, type) {
        const body = {
            enquiryId: +id,
            category: type,
            ready: true,
            start: false,
            end: false
        };
        return this.http.put('trips', body).pipe(map(event => console.log(event)));
    }

    tripStarted(id, type) {
        const body = {
            enquiryId: +id,
            category: type,
            ready: false,
            start: true,
            end: false
        };
        return this.http.put('trips', body).pipe(map(event => console.log(event)));
    }

    tripStopped(id, type) {
        const body = {
            enquiryId: +id,
            category: type,
            ready: false,
            start: false,
            end: true
        };
        return this.http.put('trips', body).pipe(map(event => console.log(event)));
    }

    getDeliveries(pos) {
        const data = {
            lat: pos.lat,
            long: pos.lng,
            range_in_meter: 3500000000
        };
        return this.http.post<ServiceProviderAuctions[]>('enquiry/delivery-nearby', data)
            .pipe(map(event => event));
    }

    submitRating(rating, comments, trip,userId) {
        return this.http.post('rating', {
            rating, comments, userId: userId, tripId: trip
        })
            .pipe(map(event => event));
    }
}
