import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from 'angularx-social-login';
import {AuthService as Authentication} from '../../services/auth.service';
import {ErrorHandlerService} from '../../services/error-handler.service';
import {NgxSpinnerService} from 'ngx-spinner';
import * as moment from 'moment';
import {Notifications, Notification} from '../../models/common';
import {SocketsService} from '../../services/sockets.service';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {languageConstants} from '../../constants/languageConstants';
import { profileStatus } from 'src/app/models/profileStatus';

@Component({
    selector: 'logibids-user-headers',
    templateUrl: './user-headers.component.html',
    styleUrls: ['./user-headers.component.css']
})
export class UserHeadersComponent implements OnInit {
    dropdown: boolean;
    lan = localStorage.getItem('language') || 'en';
    language: boolean;
    privacy: boolean;
    user: any;
    page = 1;
    checked = true;
    unreadCount: number;
    moment = moment;
    language1 = 'en';
    languageConstants = languageConstants;
    languageKey = languageConstants[this.lan];
    notifications: Notification[] = [];
    is_reqto_driver = false;

    constructor(
        private confirmationService: ConfirmationService,
        private router: Router,
        private authService: AuthService,
        private AS: Authentication,
        private errorService: ErrorHandlerService,
        private socket: SocketsService,
        private spinner: NgxSpinnerService,
        private ms: MessageService,
        private messageService: MessageService
    ) {
        const user = localStorage.getItem('user');
        this.user = user && Object.keys(user).length ? JSON.parse(localStorage.getItem('user')) : null;
        this.checked = this.user ? this.user.active : false;
        this.is_reqto_driver = this.user ? this.user.is_reqto_driver :  false
        const lang = localStorage.getItem('language');
        if(lang) {
           this.language1 = lang;
           this.languageKey = this.languageConstants[lang]
        }
    }

    ngOnInit() {
        this.getUser();
        this.notifications=[]
        this.getNotifications();
        this.getSockets();
    }

    getUser() {
        this.AS.currentUserInfo1().subscribe((user) => {
            this.user = user;
            localStorage.setItem('user',JSON.stringify(user))
            this.checkerOfProfileStatus();
        }, (err) => {
            this.errorService.handleError(err);
        });
    }

    pendingDisplay = false;
    rejectedDisplay = false;
    checkerOfProfileStatus = (): void => {
        const response = JSON.parse(localStorage.getItem('sp_data'));
        const resp = JSON.parse(localStorage.getItem('user'));
        if (response.hasOwnProperty('status')) {
            if (resp.status === profileStatus.Pending && response.hasOwnProperty('companyIdPhoto') && response.hasOwnProperty('companyLicensePhoto')) {
                this.pendingDisplay = true;
            } else if (resp.status === profileStatus.Rejected && response.hasOwnProperty('companyIdPhoto') && response.hasOwnProperty('companyLicensePhoto')) {
                this.rejectedDisplay = true;
            }
        }
    }

    onConfirm() {
        this.messageService.clear('c');
    }

    getNotifications(page = 1) {
        this.AS.getUserNotifications(page).subscribe((notifications: Notifications) => {
            this.notifications = notifications.notifications;
            this.unreadCount = notifications.unreadCount;
        }, (err) => {
            this.errorService.handleError(err);
        });
    }

    getSockets() {
        this.socket.listenNotifications().subscribe(data => {
            this.getNotifications();
        }, (error) => {
            this.errorService.handleError(error);
        });
    }

    viewedNotification() {
        this.AS.viewedNotification().subscribe(() => {
            this.page=1;
            this.notifications=[]
            this.getNotifications();
        }, (err) => {
            this.errorService.handleError(err);
        });
    }

    onScrollingFinished() {
        this.getNotifications(this.page + 1);
    }

    showNav() {
        this.dropdown = !this.dropdown;
        this.language = false;
        this.privacy = false;
    }

    showLang() {
        this.language = !this.language;
        this.dropdown = false;
        this.privacy = false;
    }

    showPrivacy() {
        this.privacy = !this.privacy;
        this.language = false;
        this.dropdown = false;
    }

    gotoPrivacy() {
        this.router.navigate(['/public/privacy']);
    }

    gotoTerms() {
        this.router.navigate(['/public/terms']);
    }

    gotoFaq() {
        this.router.navigate(['/public/faq']);
    }

    gotoContact() {
        this.router.navigate(['/public/contact']);
    }

    gotoContract() {
        this.router.navigate(['/public/contract']);
    }

    gotoChangePassword() {
        this.router.navigate(['/my-profile/change-password'], {queryParams: { phoneNumber: this.user.phone_number }});
      }

    logout() {
        const player_id = sessionStorage.getItem("onesignalToken");
        this.spinner.show();
        this.AS.logout(player_id).subscribe(() => {
            this.spinner.hide();
            let currLang = localStorage.getItem('language');
            localStorage.clear();
            this.authService.signOut(true);
            this.router.navigate(['/']);
            localStorage.clear();
            localStorage.setItem('language',currLang);
            const that = this;
            setTimeout(() => {
                that.router.navigate(['/login'], {
                    queryParams: {lang: 'en'}
                });
                localStorage.setItem('language',currLang);
            }, 1000);
        })
    }

    setLanguage(language) {
        if(this.user && this.user.preferredLanguage!=language){
          this.AS.updateUserLanguage(language).subscribe((data:any)=>{
            if(data.idToken){
              localStorage.setItem('idToken',data.idToken)
              delete data.idToken
            }
            localStorage.setItem('user',JSON.stringify(data))
            const url = document.URL.split('/');
            url[3] = language;
            window.location.href = url.join('/');
          })
        }else {
          const url = document.URL.split('/');
            url[3] = language;
            window.location.href = url.join('/');
        }
    }

    gotoMyProfile() {
        this.router.navigate(['/my-profile']);
    }

    gotoMyPreferences() {
        this.router.navigate(['/sp-profile/profile-docs']);
    }

    gotoMyDrivers() {
        this.router.navigate(['/sp/my-drivers']);
    }

    gotoMyVehicles() {
        this.router.navigate(['/sp/my-vehicles']);
    }

    goToMyWallet() {
        this.router.navigate(['/sp/wallet']);
    }

    gotoMyTrips() {
        this.router.navigate(['/sp/my-trips']);
    }

    gotoHome() {
        this.router.navigate(['/sp-bids/sp-auctions']);
    }

    gotoMyRating() {
        this.router.navigate(['/sp/my-rating']);
    }

    delete() {
        this.confirmationService.confirm({
            message: this.languageKey.account_message,
            acceptLabel: this.languageKey.acpt_lbl,
            rejectLabel: this.languageKey.rjct_lbl,
            key:'dlt',
            accept: () => {
                this.deleteUser();
            }
        });
    }

    deleteUser() {
        this.spinner.show();
        this.AS.deleteAccount().subscribe(data => {
            this.spinner.hide();
            this.logout();
        }, (err) => {
            this.spinner.hide();
            this.errorService.handleError(err);
        });
    }

    toggleStatus({checked}) {
        this.spinner.show();
        this.AS.changeStatus(checked).subscribe(data => {
            this.spinner.hide();
            const user = JSON.parse(localStorage.getItem('user'));
            user.active = checked;
            localStorage.setItem('user', JSON.stringify(user));
            if (checked) {
                this.ms.add({severity: 'success', detail: this.languageKey.toggle_active });
            } else {
                this.ms.add({severity: 'success', detail: this.languageKey.toggle_inactive });
            }
        }, (err) => {
            this.spinner.hide();
            this.errorService.handleError(err);
        });
    }

    toggleDriverStatus({checked}) {
        this.spinner.show();
        const payload = {
            is_reqto_driver: checked
        }
        this.AS.changeDriverStatus(payload).subscribe(resp => {
            this.spinner.hide();
            const user = JSON.parse(localStorage.getItem('user'));
            user.active = checked;
            localStorage.setItem('user', JSON.stringify(resp));
        }, (err) => {
            this.spinner.hide();
            this.errorService.handleError(err);
        })
    }

    getInfo(item) {
        let cat = item.payload.category;
        let enqId = item.payload.enquiryId
        const language = localStorage.getItem('language') || 'en';
        if(item.payload.pushType == 8){
            this.router.navigate(['/sp/go-trips'], {
                queryParams: {
                  type: item.payload.category,
                  id: item.payload.enquiryId
                }
              }).then(() => {
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    this.router.navigate(['/sp/go-trips'], {
                        queryParams: {
                            type: item.payload.category,
                            id: item.payload.enquiryId
                          }
                    });
                  });
              });
        } else {
            if(item.payload.category == 'DELIVERY' && this.user.type === 'SERVICE_PROVIDER'){
                this.router.navigate(['/sp-bids/sp-new-delivery'],{
                    queryParams : {  lang : language, id: enqId , type : cat  }
                }).then(() => {
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                        this.router.navigate(['/sp-bids/sp-new-delivery'], {
                            queryParams : {  lang : language, id: enqId , type : cat  }
                        });
                      });
                });
            }
            else if (this.user.type === 'SERVICE_PROVIDER') {
                this.router.navigate(['/sp-bids/sp-new-bid'], {
                    queryParams: { id: enqId, type: cat, lang: language }
                  }).then(() => {
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                      this.router.navigate(['/sp-bids/sp-new-bid'], {
                        queryParams: { id: enqId, type: cat, lang: language }
                      });
                    });
                  });
            } else {
                this.router.navigate(['/sp/my-trips']);
            }
        }
    }
}
