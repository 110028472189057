import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { MessageService } from "primeng/api";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()
export class ErrorHandlerService {
  public errorMessage = "";

  constructor(
    private router: Router,
    private auth: AuthService,
    private ms: MessageService,
    private spinner: NgxSpinnerService
  ) {}

  public handleError(error: HttpErrorResponse) {
    if (error.status === 500) {
      this.handle500Error(error);
    } else if (error.status === 401) {
      this.handle401Error(error);
    } else if (error.status === 404) {
      this.handle404Error(error);
    } else if (error.status === 406) {
      this.handle406Error(error);
    } else {
      this.handleOtherError(error);
    }
  }

  private handle500Error(error: HttpErrorResponse) {
    this.createErrorMessage(error);
    this.router.navigate(["/public/server-error"]);
  }

  private handle404Error(error: HttpErrorResponse) {
    this.createErrorMessage(error);
    this.router.navigate(["/public/maintenance"]);
  }

  private handle401Error(error: HttpErrorResponse) {
    this.createErrorMessage(error);
    if (
      error.error.msg !== "Old Password is incorrect." &&
      error.error.msg !== "Invalid ID token provided"
    ) {
      if (
        localStorage.getItem("accessToken") &&
        localStorage.getItem("idToken")
      ) {
        this.auth.refreshToken().subscribe(
          (opts) => {
            localStorage.setItem("user", JSON.stringify(opts.user));
            localStorage.setItem("accessToken", opts.accessToken);
            localStorage.setItem("idToken", opts.idToken);
            const currentUrl = this.router.url; // Get the current URL with query params
            this.router
              .navigateByUrl("/", { skipLocationChange: true })
              .then(() => {
                this.router.navigateByUrl(currentUrl); // Navigate back to the same route
              });
          },
          (err) => {
            localStorage.clear();
            this.router.navigate(["/public/unauthorized"]);
          }
        );
      }
    } else if (error.error.msg === "Invalid ID token provided") {
      localStorage.clear();
    }
  }

  protected handle406Error = (error: HttpErrorResponse): void => {
    this.spinner.hide();
    this.router.navigate(["/public/blocked"], {
      queryParams: { error: error.error.msg },
    });
  };

  private handleOtherError(error: HttpErrorResponse) {
    this.createErrorMessage(error);
  }

  private createErrorMessage(error: HttpErrorResponse) {
    this.errorMessage = error.error ? error.error.msg : error.message;
  }
}
